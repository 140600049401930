import React from 'react';
import { Button } from '../../components'

export default {
    header: {
        title: 'Camper huren in sevilla',
        subTitle: 'Huur eenvoudig onze camper!',
        background: 'camper_huren_sevilla.jpeg'
    },
    content: [
        {
            title: 'Over Sevilla',
            image: require('../../assets/sevilla.jpeg'),
            text: `Sevilla ligt in het zuiden van Spanje en is de hoofdstad van de regio Andalusië en daarmee een perfect uitgangspunt voor een rondreis door Spanje. De camper huren in Sevilla kan direct bij ons. Wil je weten of de camper beschikbaar is op de door jou gekozen periode, neem dan contact met ons op per mail via de website.\n\nOnze camper huren in Sevilla staat garant voor een heerlijke vakantie. Sevilla wordt gezien als een van de mooiste steden van Spanje, en zo denken wij er ook over! Je vindt hier een oude sfeervolle binnenstad met Arabische invloeden en in de smalle straatjes kan je gemakkelijk verdwalen. Na een paar dagen in de stad kan je met je huurcamper een rondreis maken langs de historische stedendriehoek van Andalusië bestaande uit bijvoorbeeld: Sevilla, Cordoba en Granada, maar ook steden als Malaga, Cadiz, Mijas, Ronda, Antequerra zijn een bezoekje waard. Ben je juist op zoek naar een rustige omgeving? Ga dan naar het berggebied Sierra Norte. Houd je meer van de zee? Op twee uur rijden bevinden zich diverse stranden aan de Atlantische Oceaan. Een camper huren in Sevilla geeft je alle vrijheid, zo bepaalt je zelf waar je heen rijdt. Daarnaast ervaar je de flexibiliteit om drukke bestemmingen te vermijden.`
        },
        {
            title: 'Over de camper',
            video: 'https://www.youtube-nocookie.com/embed/mjZmeUIjQBw?controls=0',
            text: `Veilig op reis gaan is belangrijk, het huren van onze camper in Sevilla is dan ook een goede en veilige keuze. De camper is uitgerust met een all-risk verzekering. Onze camper is een 6 persoons-Ford RIMOR camper uitgerust met 4 vaste slaapplaatsen heeft en heeft daarbij nog eens mogelijkheid voor 4 extra bedden (zelf om te bouwen). Dat maakt dat u er met 6 persoons mee kan reizen (6 gordelplaatsen) en ook nog eens mogelijkheid heb om 2 extra logeetjes te ontvangen tijdens uw reis. De camper is uitgerust met een zonnepaneel, 2 accu’s en een omvormer, u kunt dus ook off road reizen zonder gebruik te maken van voorzieningen zoals stroom.`
        },
        {
            title: 'Huurvoorwaarden',
            image: require('../../assets/camper_van_binnen.jpeg'),
            html: <Button href={'/camper-huren-in-sevilla/kenmerken-en-tarieven'}>
                Kenmerken en tarieven
            </Button>,
            text: `De camper staat gestald op 30 minuten rijden van de luchthaven, je kunt dus gelijk beginnen aan je vakantie na aankomst op het vliegveld in Sevilla.\n\nWil je gebruik maken van onze ophaal/terugbreng service, dat kan! Informeer bij ons naar het tarief.\n\nWij overhandigen je zelf de sleutel en maken van de gelegenheid gebruik om je wat tips te geven zowel t.a.v. het gebruik van de camper als ook t.a.v. het camperen in Spanje in het algemeen. In 2019 trokken wij 10 maanden door Europa per camper en delen graag onze ervaringen met je.\n\nDaarnaast mag je met onze camper ook de grens oversteken naar Europese buurlanden als Portugal, Gibraltar en Frankrijk. Bij ons is het ook mogelijk om, tegen betaling, je huurcamper in een ander land/stad in te leveren, informeer naar de voorwaarden.\n\nHeb je nog vragen over het huren van onze camper in Sevilla? Neem dan contact op met ons. Informeer bij ons naar het verhuurtarief.\n\nWij hanteren zelf een strikt schoonmaak beleid, u kunt er dus op vertrouwen dat we de camper grondig reinigen voor ieder verhuur zodat u er veilig mee op pad kan.\n\nDoor onze camper te huren steunt u tevens onze missie in Spanje!\n\nWij hopen u snel te verwelkomen in Andalusië.`
        }
    ]
}