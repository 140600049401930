export const even = n => n % 2 === 0;

export const images = (resources) => {
    const origin = resources.reduce( (acc, curr) => {
        const ids = curr.public_id.split('_');
        const isExist = acc.some(image => image.public_id.includes(ids[ids.length -1]) )
    
        if (!isExist) {
            acc.push(curr)
        }
    
        return acc
    }, [])
    
    return origin.reduce((acc, curr) => [
        ...acc,
        {
            src: curr.url,
            thumbnailWidth: 320,
            thumbnail: curr.url.replace("http://", "https://")
        }
    ], [])
}