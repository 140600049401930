export default {
    header: {
        title: 'Coaching en Counseling',
        subTitle: 'Al ruim 10 jaar ben ik, Hanneke actief als contextuele coach en counselor.',
        background: 'onder_een_mandarijn_boom.jpg',
    },
    content: [
        {
            title: 'Over mij',
            text: `Ik volgde tussen 2010 en 2015 een Masteropleiding Contextuele Therapie in Nederland nadat ik jarenlang als verpleegkundige en casemanager had gewerkt in de zorg. Na deze masterstudie startte ik mijn eigen praktijk. Sindsdien heb ik al veel mensen mogen helpen. Mijn doel is om mensen te laten bloeien en groeien in hun identiteit door hen te laten zien hoe context en verleden impact hebben op je handelen in het heden. Vanaf die bewustwording en verwerking, kan er gewerkt worden aan het maken van gezonde keuzes in het hier en nu.\n\nSinds ik in Spanje woon en daar ook een Spaanse coaching opleiding heb gevolgd (2022), coach en counsel ik niet alleen in de Nederlands taal, maar ook in het Spaans en in het Engels. De coaching voor mensen in Nederland is online. Wil je graag gebruik maken van deze coaching of counseling, neem dan contact op via hanneke.knol@ecmi.org of via [thetravelingseven](https://www.instagram.com/thetravelingseven).\n\nSinds 2022 doen wij, Henk en Hanneke de counseling samen. Ons hart klopt voor gezonde huwelijken en gezinnen, er is zo veel leed. We geven pre-marriage cursussen en counselen echtparen in allerlei situaties of omstandigheden. Recent mochten we zelfs voor het eerst een Spaans echtpaar in het “ECHT” verbinden.`,
            image: require('../../assets/hanneke_knol_coaching.jpeg')
        },
    ]
}