import { makeStyles } from '@material-ui/core';

export default makeStyles({
  card: {
    width: '100%',
    boxShadow: "0 0 2.5rem 0.3125rem rgba(0,0,0,.1)",
    background: '#807871'
  },
  media: {
    height: '220px'
  },
  textDecorationNone: {
    textDecoration: 'none!important',
  },
  colorLight: {
    color: '#FFF1E3',
    fontFamily: "'Dosis', sans-serif !important"
  },
  text: {
    color: '#FFF1E3',
  }
});