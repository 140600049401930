import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import { Breadcrumb, Banner, Header, TextBlock } from "../../framework";
import content from './404.content';
import withRoot from "../../theme/withRoot";

function AppNotFound() {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          The Traveling Seven | 404 not found
        </title>
        <meta
          name="description"
          content="Helaas, deze pagina hebben wij niet kunnen vinden!"
        />
        <meta
          property="og:description"
          content="Helaas, deze pagina hebben wij niet kunnen vinden!"
        />
        <meta
          property="og:title"
          content="The Traveling Seven | 404 not found"
        />
        <meta property="og:url" content={"https://thetravelingseven.com/404"} />
      </Helmet>
      <Divider />
      <Header content={content.header} />
      <Divider />
      <Breadcrumb backgroundColor="#FFF1E3" current="404 niet gevonden" />
      <Divider />
      <TextBlock content={content.content} />
      <Banner />
    </React.Fragment>
  );
}

export default withRoot(AppNotFound)