import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from './header.styles';
import withRoot from "../../../../theme/withRoot";

function Header() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={10} className={classes.container}>
        <Grid item xs={12} sm={12} md={12} className={classes.content}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
          >
           <img src={require('../../../../assets/logo.png')} alt="the traveling seven" className={classes.logo} />
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withRoot(Header);