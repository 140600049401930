import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import withRoot from '../../theme/withRoot'
import useStyles from './app-article.header.styles';

function AppHeroHeader({ subTitle, title, background }) {
  const classes = useStyles();

  const injectBackGroundImage = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  });

  return (
    <React.Fragment>
      <Grid
        container
        spacing={10}
        className={classes.container}
        justify="space-around"
        style={injectBackGroundImage(background)}
      >
        <Grid item xs={12} className={classes.content}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            className={classes.title}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            className={classes.subTitle}
            paragraph
          >
            {subTitle}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withRoot(AppHeroHeader)