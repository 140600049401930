import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './app-hero-header.styles';
import withRoot from '../../theme/withRoot';

function AppHeroHeader({ subTitle, title, background }) {
  const classes = useStyles();

  const injectBackGroundImage = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
  });

  return (
    <Grid
      container
      spacing={10}
      className={classes.container}
      justify="space-around"
      style={injectBackGroundImage(require(`../../assets/${background.toLowerCase()}`))}
    >
      <Grid item xs={12} className={classes.content}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          className={classes.title}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          className={classes.subTitle}
          style={{
            color: 'rgba(255, 255, 255, 0.5)',
          }}
          paragraph
        >
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withRoot(AppHeroHeader)