import React from 'react';
import Button from '../../components/app-button';
import { DONATE_URI } from '../../constants'
import withRoot from '../../theme/withRoot';
import useStyles from './sticky-sidebar.styles';

function StickyBar() {
  const classes = useStyles();

  return (
    <div className={classes.stickyBar}>
      <Button buttonText={'Steun ons'} href={DONATE_URI} />
    </div>
  );
}

export default withRoot(StickyBar);