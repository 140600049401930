import React from 'react';
import { Helmet } from 'react-helmet'
import { Divider } from '@material-ui/core';
import { Breadcrumb, Banner, TextBlock, Header } from '../../framework'
import withRoot from '../../theme/withRoot';
import content from './coaching.content';

function AppAbout() {
  return (
    <React.Fragment>
       <Helmet>
        <title>Voormalig verpleegkundige Hannke Knol as Contextueel coach</title>
        <meta name="description" content="Hanneke Knol is contextueel coach. Ik heb 5 jaar een eigen praktijk gehad aan huis waarin ik mensen mochten begeleiden. Het is mijn verlangen om mensen te blijven inspireren, begeleiden en bemoedigen in de toekomst."/>
        <meta property="og:description" content="Hanneke Knol is contextueel coach. Ik heb 5 jaar een eigen praktijk gehad aan huis waarin ik mensen mochten begeleiden. Het is mijn verlangen om mensen te blijven inspireren, begeleiden en bemoedigen in de toekomst."/>
        <meta property="og:title" content="The Traveling Seven | Coaching | Hanneke Knol"/>
        <meta property="og:url" content={"https://thetravelingseven.com/coaching"}/>
      </Helmet>
      <Header content={content.header}/>
      <Divider />
      <Breadcrumb backgroundColor="#FFF1E3"/>
      <Divider style={{backgroundColor: 'rgb(128 120 113)'}}/>
      <TextBlock content={content.content} />
      <Banner />
    </React.Fragment>
  );
}

export default withRoot(AppAbout)