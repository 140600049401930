import React from 'react';
import { Divider } from '@material-ui/core';
import { ImageBlock } from '../../components';
import withRoot from '../../theme/withRoot';
import { even } from '../../utils'

function TextBlocks({ content, children }) {
    return content.map((props, index) => {
        const isEven = even(index);

        return (
            <>
                <ImageBlock
                    reverse={isEven}
                    backgroundColor={!isEven && "#E8D0C3"}
                    {...props}
                >
                    {children}
                </ImageBlock>
                <Divider />
            </>
        )
    })
}

export default withRoot(TextBlocks)