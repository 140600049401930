import React from 'react';
import { Helmet } from 'react-helmet'
import { Divider } from '@material-ui/core';
import { Breadcrumb, Header, TextBlock, Banner } from '../../../../framework';
import content from './pricing.content';
import withRoot from '../../../../theme/withRoot';

function AppCamperPricing() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Tarieven voor het huren van een camper in Sevilla</title>
        <meta name="description" content="Ga je binnenkort op vakantie en wil je graag Spanje ontdekken per camper? Huur dan eenvoudig onze camper." />
        <meta property="og:description" content="Ga je binnenkort op vakantie en wil je graag Spanje ontdekken per camper? Huur dan eenvoudig onze camper." />
        <meta property="og:title" content="Camper huren in Sevilla tarieven" />
        <meta property="og:url" content={"https://thetravelingseven.com/camper-huren-in-sevilla/tarieven"} />
      </Helmet>
      <Header content={content.header} />
      <Divider />
      <Breadcrumb backgroundColor="#E8D0C3" />
      <Divider style={{ backgroundColor: 'rgb(128 120 113)' }} />
      <TextBlock content={content.content} />
      <Banner />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppCamperPricing)