import React from 'react';
import { Helmet } from 'react-helmet'
import { Divider } from '@material-ui/core';
import AppQuery from './components/query'
import withRoot from '../../theme/withRoot';
import { Banner, Breadcrumb, Header } from "../../framework";
import content from './articles.content';

function AppBlog() {
  return (
    <React.Fragment>
      <Helmet>
        <title>The Traveling Seven | nieuwsbrieven</title>
        <meta name="description" content="Blijf op de hoogte van de laatste nieuwsupdate van de Familie Knol!"/>
        <meta property="og:description" content="Blijf op de hoogte van de laatste nieuwsupdates van de Familie Knol!"/>
        <meta property="og:title" content="The Traveling Seven | nieuwsbrieven"/>
        <meta property="og:url" content={"https://thetravelingseven.com/nieuwsbrief"}/>
      </Helmet>
      <Header content={content.header} />
      <Breadcrumb backgroundColor="#E8D0C3" />
      <Divider style={{backgroundColor: 'rgb(128 120 113)'}} />
      <AppQuery />
      <Divider />
      <Banner />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppBlog)