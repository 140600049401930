import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      background: "#FFF1E3",
    },
    content: {
      fontFamily: "'Montserrat', sans-serif;",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "1.1rem",
      textAlign: "justify",
      fontWeight: 300,
      lineHeight: 1.8,
      padding: "40px 0",
    },
    title: {
      fontFamily: "'Dosis', sans-serif;",
      marginBottom: "50px",
    },
    card: {
      borderTopRightRadius: "45px",
    },
  })
);