import { createMuiTheme } from "@material-ui/core/styles";
import {
  grey,
} from "@material-ui/core/colors";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFF1E3",
      dark: '#8C7974',
    },
    secondary: {
      main: "#CCC1B6",
    },
    text: {
      primary: '#807871',
      contrastText: '#807871'
    },
    action: {
      hover: '#8C7974',
      focus: '#8C7974',
      selected: '#8C7974',
      active: '#8C7974'
    }
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    body1: {
      fontFamily: "'Montserrat', sans-serif"
    },
    body2: {
      fontFamily: "'Montserrat', sans-serif"
    },
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 500, // Work Sans
    fontWeightMedium: 700,
    fontFamilyPrimary: "'Montserrat', sans-serif",
    fontFamilySecondary: "'Montserrat', sans-serif",
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: 400,
  fontFamily: "'Dosis', sans-serif !important",
  textTransform: "uppercase",
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    ...fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2.5rem!important",
      },
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2.1rem!important",
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2rem!important",
      },
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.9rem!important",
      },
    },
    h5: {
      ...rawTheme.typography.h5,
      ...fontHeader,
      fontSize: 20,
      fontWeight: 500,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.5rem!important",
      },
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem!important",
      },
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem!important",
      },
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: 500,
      fontSize: 16,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1rem!important",
      },
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: ".9rem!important",
      },
    },
  },
};

export default theme;
