export default {
    header: {
        title: 'Tarieven voor het huren',
        subTitle: 'Kenmerken en tarieven van de camper',
        background: 'camper_huren_sevilla.jpeg'
    },
    content: [
        {
            title: 'Camper kenmerken',
            video: 'https://www.youtube-nocookie.com/embed/mjZmeUIjQBw?controls=0',
            text: `* 6 persoons camper (6 gordelplaatsen) met 8 slaapplaatsen\n* Handgeschakeld\n* Stapelbed, tweepersoonsbed in alkoof\n* 6 persoons treinzit\n* Opbergruimte onder stapelbed, van buitenaf bereikbaar\n* Zonnepaneel\n* Zonneluifel\n* Omvormer 12v-220 1200W\n* 2 accu’s\n* Verwarming\n* 100 liter watertank\n* Gasfles (navulbaar met LPG)\n* Koelkast (12v en gas)\n* Boiler\n* 3 pits gaskookplaat\n* Badkamer uitgerust met chemisch toilet met cassette, douche, wastafeltje`
        },
        {
            title: 'Huurprijs per week',
            image: require('../../../../assets/de_camper.jpg'),
            text: `De verhuur van de camper wordt per week gedaan. Afwijkingen in overleg.\n\n150 km vrij per dag.\n\n595 euro in het laagseizoen (nov-april)\n\n659 euro in het hoogseizoen (mei-okt)\n\nBorgsom: 600 euro\n\nSchade dient altijd gemeld te worden. Bij het in gebruik nemen van de camper dienen evt. aanwezige defecten direct binnen 24 uur gemeld te worden na ingaan van de huurperiode. Na afloop lopen we de camper met u na op evt. schade.`
        },
        {
            title: 'Inboedel',
            text: `U dient zelf uw inboedel te verzekeren tegen diefstal, regel dit zelf met u reisverzekering. De camper is uitgerust met een all-risk verzekering maar die dekt niet de schade en diefstal aan uw eigendommen. In geval van schade wordt er schademelding gedaan bij de verzekering en kan de afwikkeling even op zich laten wachten. (in Spanje kost dat wat meer tijd). U kunt ook overwegen bij kleinere schade om de schade zelf te vergoeden. In dat geval sturen we u een factuur van de schade na reparatie.`
        },
        {
            title: 'Ophaalservice',
            image: require('../../../../assets/camper_bij_de_bergen.jpg'),
            text: `Ophaal/terugbreng Service vliegveld Sevilla (max 4 personen per keer i.v.m. bagage).\n\nOphalen 30 euro\n\nTerugbrengen 30 euro\n\nPrijs inclusief instructie camper en tips over het kamperen in Spanje.\n\nBeddengoed, servies voor 6 personen, inbegrepen. Geef van te voren aan met hoeveel personen u bent zodat we het beddengoed kunnen aanpassen.`
        },
        {
            title: 'Tafels en stoelen',
            image: require('../../../../assets/camper_van_binnen.jpeg'),
            text: `Tafel en stoelen zijn er bij te huren voor 50 euro per week. Denk er wel aan dat u tafels en stoelen alleen kunt gebruiken op officiële camperplekken of camping.\n\nDe camper wordt volgetankt afgeleverd en dient volgetankt ingeleverd te worden na afloop van de reis.\n\nDe watertank heeft een inhoud van 100 liter. Advies om dit water niet te drinken. In alle supermarkten kun je mineraalwater kopen.`
        },
        {
            title: 'Eindschoonmaak',
            text: `Mocht u gebruik willen maken van de eindschoonmaak service, dat kan. In dat geval hoeft u alleen maar te zorgen dat de camper leeg is, bedden afgehaald en prullenbakken leeg. Wij maken de camper dan schoon na inlevering.\n\nDe kosten van de eindschoonmaak zijn 35 euro.\n\nMocht u nog vragen hebben, laat het ons gerust weten.\n\nWij hopen u spoedig te verwelkomen in Andalusië.`
        }
    ]
}