import React from "react";
import { Button as MuiButton } from "@material-ui/core";
import useStyles from './app-button.styles';
import withRoot from "../../theme/withRoot";

function Button({
  props,
  children,
  styles,
  href,
  handler = () => {},
}) {
  const classes = useStyles();

  return (
    <MuiButton
      onClick={handler}
      href={href}
      variant="contained"
      // color="primary"
      style={styles || {}}
      className={classes.button}
      {...props}
    >
      {children}
    </MuiButton>
  );
}

export default withRoot(Button)