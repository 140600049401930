import React from 'react';
import { Helmet } from 'react-helmet'
import { Divider } from '@material-ui/core';
import { Breadcrumb, Banner, TextBlock, Header } from '../../framework';
import withRoot from '../../theme/withRoot';

import content from './thuis-front-team.content';

function AppThuisFrontTeam() {
  return (
    <React.Fragment>
      <Helmet>
        <title>The Traveling Seven | Thuisfront team</title>
        <meta name="description" content="We zijn blij u te kunnen vertellen dat we een thuisfront team hebben! We hebben zes mooie mensen bereid gevonden om daar plaats in te nemen."/>
        <meta property="og:description" content="We zijn blij u te kunnen vertellen dat we een thuisfront team hebben! We hebben zes mooie mensen bereid gevonden om daar plaats in te nemen."/>
        <meta property="og:title" content="The Traveling Seven | Thuisfront team"/>
        <meta property="og:url" content={"https://thetravelingseven.com/thuisfront-team"}/>
      </Helmet>
      <Header content={content.header}/>
      <Divider />
      <Breadcrumb backgroundColor="#E8D0C3"/>
      <Divider style={{backgroundColor: 'rgb(128 120 113)'}}/>
      <TextBlock content={content.content}/>
      <Banner />
    </React.Fragment>
  );
}

export default withRoot(AppThuisFrontTeam)