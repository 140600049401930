import { makeStyles, createStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    colorDark: {
      color: '#000!important',
    },
    heartBeatIcon: {
      color: '#FFF',
      fontSize: '2rem',
    },
    heartBeatIconAwake: {
      color: '#000',
    },
    menuButton: {
      color: '#FFF',
      marginRight: '30px',
    },
    navigationBar: {
      position: 'fixed',
      top: '-74px',
      transition: 'transform 0.2s',
      transform: 'translateY(74px)',
      background: '#FFF1E3'
    },
    navigationBarHide: {
      transform: 'translateY(0)',
    },
    navigationBarInitial: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    navigationBarInner: {
      justifyContent: 'space-between',
    },
    offset: {
      height: '74px',
    },
    offset0: {
      height: 0,
    },
    root: {
      flexGrow: 1,
      maxHeight: '64px'
    },
    title: {
      fontFamily: '"Dosis", sans-serif',
      color: '#FFF',
      fontSize: '1.1rem'
    },
    titleAwake: {
      color: '#000',
    },
    logo: {
      display: 'flex',
      width: '80px',
      height: '50px',
      justifySelf: 'center',
      alignSelf: 'center',
      backgroundSize: 'cover',
      backgroundImage: `url(${require('../../assets/logo.png')})`
    },
    logoRevert: {
      backgroundImage: `url(${require('../../assets/logo_black.png')})`
    }
  }),
);