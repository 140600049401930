import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    button: {
      color: "#FFF",
      fontSize: "1.1rem",
      height: "60px",
      margin: theme.spacing(1),
      minWidth: "175px",
      backgroundColor: "#694938",
      "&:hover": {
        backgroundColor: "#B5A298",
        color: '#FFF'
      },
    },
  })
);