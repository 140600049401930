import React from "react";
import { Grid, Paper, Typography, Avatar } from "@material-ui/core";
import classNames from "classnames";
import ExploreIcon from "@material-ui/icons/Explore";

import AppButton from "../../components/app-button";
import withRoot from '../../theme/withRoot';
import { DONATE_URI } from '../../constants';
import useStyles from './banner.styles';

function ComponentTopBanner({ backgroundColor }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        xl={8}
        justify="center"
        alignItems="center"
      >
        <Paper className={classes.paperContainer}>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <Avatar
              className={classes.avatar}
              alt="Steun ons op onze missie!"
            >
              <ExploreIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
            justify="center"
            alignItems="start"
            className={classes.flex}
            direction="column"
          >
            <Typography
              component="h2"
              variant="h6"
              className={classNames(classes.contentText, classes.fontWeight700, classes.colorDark)}
            >
              Steun onze missie!
            </Typography>
            <Typography
              component="h3"
              variant="subtitle1"
              className={classNames(classes.contentText, classes.color)}
            >
              We geloven dat ons leven een reis met God is, vandaar de naam “the traveling seven”. Alles wat we doen, staat in verbinding met ons geloof in Jezus en het verlangen om de hoop en liefde die wij gevonden hebben te delen met anderen.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <AppButton
              href={DONATE_URI}
              target="_blank"
              styles={{
                backgroundColor: '#4A6942',
                color: '#FFF1E3',
                fontFamily: '"Dosis", sans-serif'
              }}
            >
              Steun ons
            </AppButton>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withRoot(ComponentTopBanner)