import React from 'react';
import { HeroHeader } from '../../components';
import withRoot from '../../theme/withRoot';

function Header({ content }) {
  return (
    <HeroHeader
      subTitle={content.subTitle}
      title={content.title}
      background={content.background}
    />
  );
}

export default withRoot(Header)