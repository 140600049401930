import React from 'react';
import { Divider } from '@material-ui/core';
import { Banner, TextBlock } from "../../framework";
import withRoot from "../../theme/withRoot";

import AppHeader from './components/header';
import TopBanner from './components/top-banner';
import content from './home.content';

function AppHome() {
  return (
    <React.Fragment>
      <Divider />
      <AppHeader />
      <Divider />
      <Banner />
      <Divider />
      <TopBanner />
      <Divider />
      <TextBlock content={content.content} />
    </React.Fragment>
  );
}

export default withRoot(AppHome);