import 'dotenv/config'
import React from 'react';
import ReactDOM from 'react-dom';
import AppMain from './app-main';
import Store from './store/store';
import './index.css';

const rootElement = document.getElementById('hh-knol');

ReactDOM.render(
    <Store>
        <AppMain />
    </Store>,
  rootElement,
);