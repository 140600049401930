import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  card: {
    width: '100%',
    boxShadow: "0 0 2.5rem 0.3125rem rgba(0,0,0,.07)",
    background: "#807871",
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  textDecoration : {
    textDecoration: 'none!important'
  },
  colorLight: {
    color: '#FFF1E3'
  },
  fontFamilyDosis: {
    fontFamily: "'Dosis', sans-serif"
  }
}));
