import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    drawerLink: {
      display: 'flex',
      borderBottom: '.5px solid rgba(50, 50, 50, .2)',
      textDecoration: 'none',
      fontFamily: '"Montserrat", sans-serif',
    },
    drawerText: {
      display: 'flex',
      justifyContent: 'center',
      color: '#333',
    },
  }),
);