export default {
    header: {
        title: 'Thuisfront team (TFT)',
        background: 'samen_in_spanje.jpg',
        subTitle: 'Achter de schermen',
    },
    content: [
        {   
            title: 'Wat is een thuisfrontteam?',
            image: require('../../assets/het_gezin_met_hond.jpg'),
            text: `Een thuisfrontteam (afkorting TFT) is een groep mensen die ons als zendelingen ondersteunt vanuit het land van herkomst, in dit geval Nederland. [Ons TFT](/thuisfront-team/leden) heeft als doel om ons als gezin te steunen en daar zijn we heel blij mee! 
            In het woord thuisfrontteam is er bewust gekozen voor het woord TEAM: **T**ogether, **E**verybody **A**chieves **M**ore (samen bereik je meer), samen sta je voor hetzelfde doel, namelijk getuigen van Jezus tot uitbreiding van Gods Koninkrijk.
            \nHeel concreet en heel specifiek betekent het dat [het TFT](/thuisfront-team/leden) met ons er samen zorg voor draagt dat onze uitzending mogelijk wordt en blijft. Elk lid van [het TFT](/thuisfront-team/leden) heeft daarin zijn eigen taak en zet zich in op zijn eigen manier met zijn eigen ideeën, gaven en talenten.
            `
        },
        {
            title: 'Waarom een TFT?',
            image: require('../../assets/wandelen_door_d_duinen.jpg'),
            text: `God geeft ons de opdracht om samen te bouwen in Gods Koninkrijk om Zijn liefde in woord en daad te verkondigen, ieder met zijn eigen gaven en talenten en ieder op zijn eigen plek. Wij als zendingswerkers kunnen dit niet alleen. We hebben daarom mensen bereid gevonden om ons hierin te steunen.
            \nIeder thuisfrontteamlid zet zich op die manier vanuit Nederland in om bij te dragen aan de verspreiding van de boodschap van het evangelie in Spanje.
            \nWe zijn dankbaar dat we [een mooi team van mensen](/thuisfront-team/leden) bereid hebben gevonden om ons hierin te ondersteunen.
            `
        },
        {
            title: 'Wat doet een TFT?',
            image: require('../../assets/henk_hanneke.jpg'),
            text: `
            \n[Het TFT](/thuisfront-team/leden) vergadert regelmatig om de voortgang van de uitzending te bespreken. Onderwerpen die aan bod komen zijn:
    
            \n * gebed voor ons als zendingswerkers
            \n * financiële support
            \n * contact houden met ons en onze achterban.
            \n * ons vertegenwoordigen in NL binnen o.a. kerken, verenigingen en bij andere geinterresseerden.
            `
        }
    ]
}

