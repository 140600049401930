export default {
    header: {
        title: 'Leden van het thuisfront team (TFT)',
        background: 'het_gezin_met_hond.jpg',
        subTitle: 'Een kijkje achter de schermen!',
    },
    content: [
        {
            image: require('../../../../assets/eline_tft.jpg'),
            text: `Ik ben Eline, 35 jaar, getrouwd met Jacques (broer van Hanneke) en samen hebben we drie kinderen. In de afgelopen jaren hebben Jacques en ik met Henk en Hanneke gesproken over hoe je invulling geeft aan discipelschap in het dagelijks leven. Net als Henk en Hanneke is het mijn verlangen om Jezus en Zijn boodschap van liefde en genade bekend te maken. Als gebedscoördinator mag ik hen ondersteunen en ook -heel praktisch- in het meeschrijven en redigeren van de nieuwsbrieven.`,
            title: 'Eline van der Bijl'
        },
        {
            image: require('../../../../assets/esther_tft.jpg'),
            title: 'Esther Knol',
            text: `Mijn naam is Esther, 38 jaar, getrouwd met Maarten en samen hebben we 3 kinderen. Als zus ben ik erg betrokken bij Henk en Hanneke en hun kinderen. Ik zie hun liefde voor de Spanjaarden groeien en hun honger om daar de liefde van Jezus te laten zien. Als voorzitter binnen het TFT ben ik dankbaar en trots dat wij samen dienstbaar mogen zijn en zo bouwen aan het koninkrijk van God in Spanje.`,
        },
        {
            image: require('../../../../assets/pete_kate_tft.jpg'),
            title: 'Peter en Kate, de mentoren van Henk en Hanneke',
            text: `We have been in ECM for more than thirty years. We have brought up four boys on the field. Peter first worked with an Evangelist in Málaga, then with several church planting teams in the region of Córdoba. We transferred to Peñarroya to take over from the missionary couple who were needing a break. Since 2004 Peter has been teaching part time at the Seminary and Faculty of the Assemblies of God. Kate is dedicated to discipling women in the church and amongst the neighbours.`
        },
        {
            image: require('../../../../assets/ronald_tft.jpg'),
            title: 'Ronald Kuyper',
            text: `Ik ben Ronald, 56 jaar. Ik heb Henk en Hanneke leren kennen vanuit de kerkelijke gemeente in Zutphen. Later maakten ze ook deel uit van de huiskring in Vorden. Ze zijn lieve vrienden van mijn vrouw Ellen en mij. Al jaren delen we lief en leed met elkaar. We voelden ons direct betrokken bij de voorbereidingen rondom hun uitzending. Niet in de laatste plaats omdat we onszelf ook met Spanje verbonden voelen. De afgelopen 16 jaar bezochten we het land elk jaar wel een keer. Graag ondersteun ik hen op een praktische manier, juist op het moment dat ze in Spanje zijn.`
        },
        {
            image: require('../../../../assets/douwe_hettema.jpeg'),
            title: 'Douwe Hettema',
            text: `Ik ben getrouwd met Ruth en samen hebben wij 2 zonen. Ruth is een nicht van Henk en op die manier zijn wij ook betrokken geraakt bij het werk van Henk en Hanneke en hun kinderen. Wij als gezin zijn ervan overtuigd dat Jezus De Weg en de waarheid is en daarom zetten wij ons leven in om mee te bouwen aan Gods koninkrijk. Zo ook wil ik betrokken zijn bij de ondersteuning van Henk & Hanneke en maak ik onderdeel uit van het ThuisFrontTeam. Om zo mede handen en voeten te kunnen geven om Gods liefde te laten zien en uitdelen, aan de mensen die op hun pad geplaatst worden in Spanje.`
        }
    ]
}

