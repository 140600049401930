import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { YouTube, Facebook, Instagram } from '@material-ui/icons';
import classNames from "classnames";
import useStyles from './footer.styles';
import withRoot from '../../theme/withRoot';

function Footer() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={10} className={classes.container} justify="space-around">
        <Grid item xs={12} sm={12} md={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Missie
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.text}>
            Als gezin Gods liefde laten zien en uitdelen aan de mensen die op ons pad geplaatst worden in Spanje.<br/>
            Onze missie is gebaseerd op de oproep uit de Bijbel in Mattheüs 28, vers 19-20:<br/>
            <i>“Ga dan heen, onderwijs al de volken, hen dopend in de Naam van de Vader en de Zoon en de Heilige Geest, hun lerend alles wat Ik u geboden heb, in acht te nemen. En zie, Ik ben met u al de dagen, tot de voleinding van de wereld. Amen.”</i><br/>
            Sinds twee jaar zijn we hier op allerlei manieren mee bezig geweest. Lees hierover verder onder “Wat we doen”.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Meer info
          </Typography>
          <Typography variant="body1" className={classes.anchor}>
            <Link href="https://www.ecmnederland.nl/" target="_blank" color="inherit">
              ECM Nederland
            </Link>
            <Link href="/thuisfront-team" color="inherit">
              Het thuisfront team
            </Link>
            <Link href="/thuisfront-team/leden" color="inherit">
              Leden van het TFT
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Contact
          </Typography>
          <Typography variant="body1" className={classes.anchor}>
            <Link href="mailto:henk.knol@ecmi.org" target="_blank" color="inherit">
            henk.knol@ecmi.org
            </Link>
            <Link href="mailto:hanneke.knol@ecmi.org" target="_blank" color="inherit">
            hanneke.knol@ecmi.org
            </Link>
            <Grid container spacing={10} justify="flex-start">
              <Grid item xs={1}>
                <Link href="https://www.youtube.com/channel/UCr43y6Iz8P-y3z4wmeC1bWg?sub_confirmation=1" target="_blank" color="inherit">
                <YouTube />
                </Link>
              </Grid>
              <Grid item xs={1}>
                <Link href="https://www.instagram.com/thetravelingseven/?hl=nl" target="_blank" color="inherit">
                <Instagram />
                </Link>
              </Grid>
              <Grid item xs={1}>
                <Link href="https://www.facebook.com/pages/category/Mission/The-traveling-seven-109260310856835/" target="_blank" color="inherit">
                <Facebook />
                </Link>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classNames([classes.container, classes.backgroundMain])}
        justify="space-around"
      >
        <Grid item xs={12} className={classes.content}>
          <Typography variant="body1" className={classes.anchor}>
            <Link
              href="//datails.nl"
              target="_blank"
              color="inherit"
              className={classes.colorLight}
            >
              Powered By DATAILS.
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default withRoot(Footer);