import React from 'react';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withRoot from '../../theme/withRoot'
import useStyles from './app-card.styles';

function AppCard({ buttonText, desc, image, title, href }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea src href={href} className={classes.textDecorationNone}>
        <CardMedia component="img" alt={title} height="140" image={image} title={title} className={classes.media}/>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" className={classes.colorLight}>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p" className={classes.text}>
            {desc}
          </Typography>
        </CardContent>
        {href && buttonText ? (
        <CardActions>
          <Button href={href}  className={classes.colorLight} >
            {buttonText}
          </Button>
        </CardActions>
      ) : (
        ''
      )}
      </CardActionArea>
    </Card>
  );
}

export default withRoot(AppCard)
