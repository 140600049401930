import React from 'react';
import Articles from "../../../../components/app-articles";
import withRoot from '../../../../theme/withRoot';
import { useFetch } from '../../../../hooks/useFetch'
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Loader } from '../../../../framework';

function AppQuery() {
  const { data, error } = useFetch('/data.json')

  if (!data) {
    return (
      <Backdrop open>
        <CircularProgress size={65} />
      </Backdrop>
    )
  }

  if (error) {
    return (
      <Loader severity='error' message="Ophalen van nieuwsbrieven is mislukt." visible />
    )
  }

  return (
    <Articles articles={data.sort(function(a,b){
      return new Date(b.data.published_at) - new Date(a.data.published_at);
    })} />
  );
};

export default withRoot(AppQuery);