import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    container: {
      margin: '0',
      width: '100%',
      padding: '40px',
      background: "#E8D0C3",
    },
    content: {
      display: 'flex',
      justifyContent: 'center'
    }
  }),
);