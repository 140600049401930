import React from 'react';
import { useRoutes, useInterceptor } from 'hookrouter'
import AppHome from '../pages/home';
import AppArticles from '../pages/articles'
import AppArticle from '../pages/article'
import AppAbout from '../pages/about'
import AppCoaching from '../pages/coaching'
import AppThuisFrontTeam from '../pages/thuis-front-team'
import AppThuisFrontTeamLeden from '../pages/thuis-front-team/pages/thuis-front-team-leden'
import AppVisie from '../pages/visie'
import AppCamper from '../pages/camper'
import AppCamperPricing from '../pages/camper/pages/pricing'
import Page404 from '../pages/404'

const routes = {
  '/': () => <AppHome />,
  '/nieuwsbrief': () => <AppArticles />,
  '/nieuwsbrief/:title': ({ title }) => <AppArticle slug={decodeURI(title).replace(/\s+/g, '-').toLowerCase()} />,
  '/over-ons': () => <AppAbout />,
  '/over-ons/onze-visie': () => <AppVisie />,
  '/coaching': () => <AppCoaching />,
  '/thuisfront-team': () => <AppThuisFrontTeam />,
  '/thuisfront-team/leden': () => <AppThuisFrontTeamLeden />,
  '/camper-huren-in-sevilla': () => <AppCamper />,
  '/camper-huren-in-sevilla/kenmerken-en-tarieven': () => <AppCamperPricing />
};

const App = () => {
  useInterceptor((currentPath, nextPath) => {
    window.scrollTo(0, 0);
    return nextPath
  })

  const routeResult = useRoutes(routes);
  return routeResult || <Page404 />;
}

export default App;
