import React, { useContext, useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import AppSnackbar from '../snackbar'
import { Context } from '../../store/store';
import useStyles from './loader.styles';
import withRoot from '../../theme/withRoot';

function Loader({ message, severity, visible }) {
  const classes = useStyles();
  const [state] = useContext(Context);
  const [snackBar, setSnackBar] = useState({
    open: true,
  });

  const _handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar({
      open: false,
    });
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={visible || state.loader}>
        <CircularProgress color="inherit" size={65}/>
        <AppSnackbar message={message} severity={severity} handler={_handleClose} open={snackBar.open}/>
      </Backdrop>
    </div>
  );
}

export default withRoot(Loader);