import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    colorWhite: {
      color: "#fff",
    },
    container: {
      minHeight: "100vh",
      margin: "-65px 0 0",
      width: "100%",
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `url(${require(`../../../../assets/familie_2023.jpeg`)})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
    //   backgroundPosition: "center",
      [theme.breakpoints.down("sm")]: {
        minHeight: "50vh",
        backgroundAttachment: "inherit",
      },
    },
    content: {
      alignItems: "center",
      display: "flex",
      textAlign: "left",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "550px",
      textAlign: 'center',
      textShadow: '-1px 2px #020202',
      color: '#FFF1E3',
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        maxWidth: "100%",
        textAlign: "center",
      },
    },
    logo: {
      height: '280px',
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    subTitle: {
      color: "#FFF",
      fontFamily: "'Montserrat', sans-serif;",
      fontSize: "1rem",
      fontSize: "22px",
      fontStyle: 'italic',
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);