import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import withRoot from '../../theme/withRoot'
import useStyles from './app-text-image.styles';

function AppTextImage({
  text,
  title,
  video,
  reverse = false,
  image,
  backgroundColor,
  children,
  html,
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="space-around"
      direction={reverse ? "row-reverse" : "row"}
      className={classes.container}
      spacing={10}
      style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
    >
      <Grid item sm={12} md={7} className={classes.content}>
        {title && (
          <Typography variant="h2" gutterBottom className={classes.title}>
            {title}
          </Typography>
        )}
        <ReactMarkdown source={text} className={classes.text} />
        {children}
        {html}
      </Grid>
      <Grid item sm={12} md={5} className={classes.content}>
        {image && <img src={image} alt={title} className={classes.image} />}
        {video && <iframe width="100%" height="315" src={video} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
      </Grid>
    </Grid>
  );
}

export default withRoot(AppTextImage)