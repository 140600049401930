import React from "react";
import { Grid } from '@material-ui/core';
import Card from "../app-article";
import withRoot from '../../theme/withRoot';
import useStyles from './app-article.styles';

const Articles = ({ articles }) => {
  const classes = useStyles();
  const leftArticlesCount = Math.ceil(articles.length / 5);
  const leftArticles = articles.slice(0, leftArticlesCount);
  const rightArticles = articles.slice(leftArticlesCount, articles.length);

  return (
    <Grid container spacing={10} className={classes.container} justify="space-evenly">
      {leftArticles.map(({ data: { attributes: article }}, i) => {
          return (
            <Grid item xs={12} sm={12} md={8} lg={4} className={classes.content}>
              <Card article={article} key={`article__${article.id}`} />
            </Grid>);
        })}
        {rightArticles.map(({ data: { attributes: article }}, i) => {
          return (
            <Grid item xs={12} sm={12} md={4} lg={4} className={classes.content}>
              <Card article={article} key={`article__${article.id}`} />
            </Grid>);
        })}
    </Grid>
  );
};

export default withRoot(Articles);
