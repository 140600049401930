export default {
    header: {
        title: 'Pagina niet gevonden!',
        subTitle: '404 not found',
        background: 'henk_hanneke.jpg',
    },
    content: [
        {
            title: 'Pagina niet gevonden!',
            text: 'Sorry deze pagina hebben wij niet kunnen vinden.'
        }
    ]
}