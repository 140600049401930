import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    stickyBar: {
      position: 'fixed',
      zIndex: 1200,
      top: '45%',
      left: '-50px',
      transform: 'rotate(90deg)',
    },
  }),
);
