import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import useStyles from './snackbar.styles';
import withRoot from '../../theme/withRoot';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AppSnackBar({ message, severity, handler, open }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handler}>
        <Alert onClose={handler} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default withRoot(AppSnackBar);