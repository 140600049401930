import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '../../../../components';
import withRoot from '../../../../theme/withRoot'
import useStyles from './banner.styles';

function TopBanner() {
  const classes = useStyles();

  return (
    <div id="top-banner">
      <Grid container spacing={10} className={classes.container} justify="space-evenly">
      <Grid item sm={12} justify="center" align="center">
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Volg The Traveling Seven
            </Typography>
            <Typography variant="subtitle1" component="h3">
              Lees hier meer over de meest recente details van onze reis en het team dat ons daarbij ondersteunt!
            </Typography>
          </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.content}>
          <Card
            buttonText="Bekijk onze nieuwsbrief"
            desc="Volg ons werk in Spanje!"
            image={require('../../../../assets/tt7_in_tuinen_2021.jpeg')}
            title="Nieuwsbrief"
            href={'/nieuwsbrief'}
            buttonStyle={{
              backgroundColor: '#f57c00',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.content}>
          <Card
            buttonText="Thuisfront team"
            desc="Blijf op de hoogte van het werk achter de schermen."
            image={require('../../../../assets/foto_met_zn_allen.jpg')}
            title="Thuisfront team"
            href={'/thuisfront-team'}
            buttonStyle={{
              backgroundColor: '#f57c00',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withRoot(TopBanner)