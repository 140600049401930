import React, { useContext } from 'react';
import { navigate } from 'hookrouter';
import { List, Divider, ListItem, ListItemText, SwipeableDrawer } from '@material-ui/core';
import { Context } from '../../store/store';
import { DONATE_URI } from '../../constants';
import withRoot from '../../theme/withRoot'
import useStyles from './drawer.styles';

const listItemMap = [
  ['/', 'Home'],
  ['/over-ons', 'Over ons'],
  ['/over-ons/onze-visie', 'Onze visie'],
  ['/nieuwsbrief', 'Nieuwsbrieven'],
  ['/coaching', 'Coaching'],
  ['/thuisfront-team', 'Thuisfront team'],
  ['/camper-huren-in-sevilla', 'Camper huren']
];

function StaticListItem([itemRoute, itemName]) {
  const classes = useStyles();

  return (
    <ListItem button className={classes.drawerLink} onClick={() => navigate(itemRoute)}>
      <ListItemText primary={itemName} className={classes.drawerText} />
    </ListItem>
  )
}

function AppDrawer() {
  const [state, dispatch] = useContext(Context);

  const updateDrawer = () => {
    dispatch({
      type: 'TOGGLE_DRAWER',
    });
  };

  const classes = useStyles();

  const sideList = (
    <div className={classes.list}>
      <Divider />
      <List>
        {listItemMap.map(StaticListItem)}
        <ListItem button className={classes.drawerLink} onClick={() => window.open(DONATE_URI)}>
          <ListItemText primary={'Steun ons'} className={classes.drawerText} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer open={state.drawer} onClose={updateDrawer} onOpen={updateDrawer}>
        <div tabIndex={0} role="button" onClick={updateDrawer} onKeyDown={updateDrawer}>
          {sideList}
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default withRoot(AppDrawer)