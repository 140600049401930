import React from 'react';
import { Button, Card, CardMedia, CardActions, CardActionArea, CardContent, Typography, IconButton } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import classNames from 'classnames'
import withRoot from '../../theme/withRoot'
import useStyles from './app-article.styles';

function AppArticle({ article }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
       <CardActionArea href={`/nieuwsbrief/${article.title.replace(/\s+/g, '-').toLowerCase()}`} className={classes.textDecoration}>
        <CardMedia component="img" alt={article.image?.data?.attributes?.caption} height="220" src={article.image?.data?.attributes?.formats?.small?.url} title={article.title}/>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" className={classes.colorLight}>
          {article.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.colorLight}>
            {article.published_at}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
        <Button href={`/nieuwsbrief/${article.title.replace(/\s+/g, '-').toLowerCase()}`} className={classNames(classes.colorLight, classes.fontFamilyDosis)}>
            Lees verder
        </Button>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default withRoot(AppArticle)