import React from "react";
import { Grid, Divider, Backdrop, CircularProgress } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

import { useFetch } from '../../hooks/useFetch'
import { Loader } from '../../framework';
import { ArticleHeader } from "../../components";
import { Breadcrumb, Banner } from "../../framework";
import withRoot from '../../theme/withRoot'
import useStyles from './article.styles';

const getArticle = (articles, slug) => articles.find(({ data: { attributes: article } }) => article.title.replace(/\s+/g, '-').toLowerCase() === slug) 

const Article = ({ slug }) => {
  const classes = useStyles();
  const { data, error } = useFetch('/data.json')

  if (!data) {
    return (
      <Backdrop open>
        <CircularProgress size={65} />
      </Backdrop>
    )
  }

  if (error) {
    return (
      <Loader severity='error' message="Ophalen van artikelen is mislukt." visible />
    )
  }

  const { data: { attributes: article} } = getArticle(data, slug)

  if (!article) {
    return null;
  }

  return (
    <>
        <Helmet>
          <title>{article.title}</title>
          <meta name="description" content={`The Traveling Seven | ${article.title}`}/>
          <meta property="og:image" content={article.image?.data?.attributes?.formats?.thumbnail?.url}/>
          <meta property="og:description" content={`The Traveling Seven | ${article.title}`}/>
          <meta property="og:title" content={article.title}/>
          <meta property="og:url" content={`https://thetravelingseven.com/nieuwsbrief/${slug}`}/>
          <meta property="og:type" content="article"/>
          <meta property="og:site_name" content="thetravelingseven.com"/>
      </Helmet>
      <ArticleHeader title={article.title} background={article.image?.data?.attributes?.url}/>
      <Breadcrumb backgroundColor="#FFF1E3" current={article.title.replace(/\s+/g, '-').toLowerCase()} />
      <Divider />
      <Grid container className={classes.container} justify="space-evenly">
        <Grid item xs={11} sm={10} md={8} justify="center" align="center">
          <ReactMarkdown source={article.content} className={classes.content} />
          <div class="fb-comments" data-href={`https://thetravelingseven.com/nieuwsbrief/${slug}`} data-numposts="5" data-width="100%"></div>
        </Grid>
      </Grid>
      <Banner backgroundColor="#ffe5ca" />
    </>
  );
};

export default withRoot(Article);
