import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    anchor: {
      color: '#807871',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1rem',
      lineHeight: 2,
      fontFamily: '"Montserrat", sans-serif',
    },
    container: {
      background: '#E8D0C3',
      margin: '0',
      width: '100%',
      padding: '40px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '40px',
    },
    margin: {
      margin: theme.spacing(1),
    },
    title: {
      color: '#807871',
      fontSize: '1.5rem',
      fontFamily: '"Dosis", sans-serif',
      fontWeight: 500,
      marginBottom: '1.5rem',
    },
    text: {
      color: '#807871',
      fontSize: '1rem',
      lineHeight: 1.2,
      fontFamily: '"Montserrat", sans-serif',
      textAlign: 'justify',
    },
    backgroundMain: {
      padding: "0",
      textAlign: "center",
      background: "#694938",
    },
    colorLight: {
      color: "#FFF1E3",
      fontWeight: 700,
    },
  }),
);