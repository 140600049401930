import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    colorWhite: {
      color: '#fff',
    },
    container: {
      minHeight: '450px',
      margin: '-65px 0 0',
      width: '100%',
    },
    content: {
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0, 0.4)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '40px',
    },
    title: {
      color: 'white',
      fontFamily: '"Dosis", sans-serif',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    subTitle: {
      color: 'white',
      fontFamily: '"Dosis", sans-serif',
      fontSize: '1rem',
    },
  }),
);