import { createStyles, makeStyles } from "@material-ui/core";
  
export default makeStyles((theme) =>
    createStyles({
      paperContainer: {
        background: "#694938",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        padding: "40px 0",
        fontFamily: "'Montserrat', sans-serif",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          textAlign: "center",
        },
      },
      container: {
        margin: "0",
        width: "100%",
        background: "#FFF1E3",
        padding: "120px 0",
        [theme.breakpoints.down("sm")]: {
          padding: 0,
        },
      },
      content: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "0 !important",
        color: '#FFF'
      },
      icon: {
        width: "5rem",
        height: "5rem",
        color: "#FFF1E3",
      },
      avatar: {
        width: "8rem",
        height: "8rem",
        backgroundColor: "#4A6942",
      },
      flex: {
        display: "flex",
      },
      contentText: {
        color: "#FFF",
        [theme.breakpoints.down("sm")]: {
          padding: "15px 0 0",
        },
      },
      fontWeight700: {
        fontWeight: 700,
      },
      colorWhite: {
        color: '#FFF'
      }
    })
  );