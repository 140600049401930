import React from 'react';
import { Helmet } from 'react-helmet'
import { Divider } from '@material-ui/core';
import { Breadcrumb, Banner, Header, TextBlock } from '../../framework'
import content from './visie.content';
import withRoot from '../../theme/withRoot';

function AppVisie() {
  return (
    <React.Fragment>
      <Helmet>
        <title>The Traveling Seven | visie van Henk en Hannke Knol</title>
        <meta name="description" content="Als gezin, wilt de familie Knol Gods liefde laten zien en uitdelen, aan de mensen die op hun pad geplaatst worden in Spanje."/>
        <meta property="og:description" content="Als gezin, wilt de familie Knol Gods liefde laten zien en uitdelen, aan de mensen die op hun pad geplaatst worden in Spanje."/>
        <meta property="og:title" content="The Traveling Seven | over de familie Knol"/>
        <meta property="og:url" content={"https://thetravelingseven.com/over-ons"}/>
      </Helmet>
      <Header content={content.header} />
      <Divider />
      <Breadcrumb backgroundColor="#FFF1E3" current="onze-visie"/>
      <Divider style={{backgroundColor: 'rgb(128 120 113)'}}/>
      <TextBlock content={content.content} />
      <Banner />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppVisie)