export default {
    header: {
        title: 'Over The Traveling Seven',
        subTitle: 'Het verhaal achter The Traveling Seven',
        background: 'ontmoetingen_onderweg.jpg'
    },
    content: [
        {
            title: 'Over ons',
            image: require('../../assets/the_traveling_seven_family_202.jpeg'),
            text: `We zijn een reislustig en avontuurlijk gezin met vier kinderen, dat de afgelopen jaren heel wat avonturen heeft beleefd. De aaneenschakeling van avonturen werd getriggerd door een simpele vraag: “Wat als God iets anders met ons wil?”; zouden wij dan in staat zijn om daarop te reageren?\nWe zijn met ons gezin al in Zuid Afrika geweest, we hebben gereisd door Europa per camper en we hebben korte trips gedaan naar o.a. Lesbos om vluchtelingen te helpen en Marokko om vervolgde christenen te bemoedigen. We reageren graag op wat God van ons vraagt, en dat maakt dat ons leven een avontuur is met Hem! Op dit moment wonen we in Spanje!\nWil je meer weten over ons werk en leven in Spanje klik dan op [onze visie](/over-ons/onze-visie).`
        },
    ]
}