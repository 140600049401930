import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { Context } from '../../store/store';
import withRoot from '../../theme/withRoot'
import useStyles from './navigation.styles';

function AppNavigation() {
  const classes = useStyles();

  const [, dispatch] = useContext(Context);

  const [atTopOfPage, setAtTopOfPage] = useState(true);
  const [sleep, setSleep] = useState(false);

  const handleScroll = () => {
    setAtTopOfPage(window.pageYOffset < 275 ? true : false);
    setSleep(window.pageYOffset < 350 ? true : false);
  };

  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE_DRAWER',
    });
  };

  useEffect(() => {
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => window.addEventListener('scroll', handleScroll), []);

  return (
    <div className={classes.root}>
      <AppBar
        color="default"
        position="relative"
        className={
          atTopOfPage
            ? classes.navigationBarInitial
            : sleep
            ? classNames(classes.navigationBar, classes.navigationBarHide)
            : classes.navigationBar
        }
      >
        <Toolbar className={classes.navigationBarInner}>
          <IconButton
            edge="start"
            className={atTopOfPage ? classes.menuButton : classNames(classes.menuButton, classes.colorDark)}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <span className={atTopOfPage ? classes.title : classNames(classes.title, classes.titleAwake)}>
            {document.location.hostname}
          </span>
          <a
            href="/"
            className={
              atTopOfPage ? classes.heartBeatIcon : classNames(classes.heartBeatIcon, classes.heartBeatIconAwake)
            }
          >
            <span className={
              atTopOfPage ? classes.logo : classNames(classes.logo, classes.logoRevert)
            }></span>
          </a>
        </Toolbar>
      </AppBar>
      <div className={atTopOfPage ? classes.offset0 : classes.offset}></div>
    </div>
  );
}

export default withRoot(AppNavigation)